window.ATL_JQ_PAGE_PROPS = {
  "triggerFunction": function (showCollectorDialog) {
    jQuery(document).on('click', "#myCustomTrigger", function (e) {
      {
        e.preventDefault();
        showCollectorDialog();
      }
    });
  }
};
